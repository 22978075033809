import React from 'react';
import reqwest from "reqwest";
import {Button, Modal, Input} from "antd";

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

class IntegrationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
        };
    }

    modal = (body, title) => {
        Modal.info({
            title: title,
            width: 350,
            maskClosable: true,
            destroyOnClose: true,
            content: body,
            onOk() {},
        });
    }

    render() {


        const showModal = () => {
            this.setState({isModalVisible: true});
        };

        const handleOk = () => {
            reqwest({
                url: `https://bpm-run.ru/api/integration_pair?token=${params.token}&amo_token=${this.state.token_in}`,
                method: 'get',
                contentType: "application/json",
                type: 'json',
            }).then((data) => {
                if (data.result === "incorrect pair token!") {
                    this.modal("Некорректный токен! Проверьте правильность и попробуйте еще раз", "Ошибка")
                }
                else {
                    this.modal("Виджет успешно установлен", "Успех")
                }
            })
            this.setState({isModalVisible: false});
        };

        const handleCancel = () => {
            this.setState({isModalVisible: false});
        };

        return (
            <>
                <Button onClick={showModal}>
                    {this.props.button_name}
                </Button>
                <Modal destroyOnClose={true} title="Установка виджета" visible={this.state.isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    Пожалуйста, введите ваш токен из виджета в <a href={"https://amocrm.ru"}>amocrm.ru</a>.<br/>В поиске виджетов вбейте <a href={"https://bpm-run.ru"}>bpm-run.ru</a>
                    <br/>
                    <br/>
                    <Input placeholder="Ваш токен" onChange={(dat) => this.setState({token_in: dat.target.value})}/>
                </Modal>
            </>
        );
    }
}

export default IntegrationModal;