import React, {useContext, useEffect, useRef, useState} from 'react';
import 'antd/dist/antd.css';
import {
    Button,
    Form, Input, Modal,
    Popconfirm,
    Space,
    Table,
} from 'antd';

import reqwest from 'reqwest';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const edit_request = (row, token) => {
    reqwest({
        url: `https://bpm-run.ru/api/var_edit?token=${token}`,
        method: 'put',
        contentType: "application/json",
        type: 'json',
        data: JSON.stringify({id: row.id, key: row.key, value: row.value, sc_id: row.sc_id})
    })
}

const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };


    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} обязательно для ввода.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

class Vars extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Ключ',
                dataIndex: 'key',
                key: 'key',
                editable: true,
            },
            {
                title: 'Значение',
                dataIndex: 'value',
                key: 'value',
                editable: true,
            },
            {
                title: 'Действие',
                key: 'action',
                render: (d, row) => (
                    <Space size="middle">
                        <Popconfirm
                            title="Вы уверены что хотите удалить переменную?"
                            onConfirm={() => this.handleDelete(row)}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Button>Удалить</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        ];

        this.state = {
            dataSource: [],
            currentPage: 1,
            id: this.getQuery().id,
            token: this.getQuery().token
        };
        this.ws_connect();
        this.fetch(1, "https://bpm-run.ru/api/vars")
    }

    handleSave = (row) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];

        newData.splice(index, 1, { ...item, ...row });

        this.setState({
            dataSource: newData,
        });

        edit_request(newData.splice(index, 1, { ...item, ...row })[0], this.state.token);
    };

    getQuery = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return Object.fromEntries(urlSearchParams.entries());
    }

    addVar = () => {
        reqwest({
            url: `https://bpm-run.ru/api/var?token=${this.getQuery().token}`,
            method: 'post',
            type: 'json',
            contentType: "application/json",
            data: JSON.stringify({key: this.state.key, value: this.state.value, sc_id: this.getQuery().id}),
        });
    }

    handleDelete = (row) => {
        reqwest({
            url: `https://bpm-run.ru/api/var_del?token=${this.getQuery().token}&id=${row.id}`,
            method: 'get',
            type: 'json',
        })
        const dataSource = [...this.state.dataSource];
        const row_index = dataSource.findIndex((item) => item.id === row.id)
        dataSource.splice(row_index, 1);
        this.setState({
            dataSource: dataSource,
        });
    }

    fetch = (page, url = {}) => {
        reqwest({
            url: url,
            method: 'get',
            type: 'json',
            data: this.getPage(page)
        }).then(data => {
            this.setState({
                dataSource: data.result,
                count: data.length,
            });
        });
    }

    modalRequest = () => {
        Modal.info({
            title: "Введите ключ и значение переменной",
            width: 650,
            maskClosable: true,
            destroyOnClose: true,
            content: (
                <div style={{marginTop: 30}}>
                    <Input onChange={(e) => this.setState({key: e.target.value})} style={{width: 250}}/> = <Input onChange={(e) => this.setState({value: e.target.value})} style={{width: 250}}/>
                </div>
            ),
            onOk: () => this.addVar()
        });
    }

    getPage = (page) => {
        return {
            id: this.state.id,
            token: this.state.token,
            limit: 10,
            offset: (page * 10) - 10,
        }
    }

    ws_connect = () => {
        this.websocket = new WebSocket(`wss://bpm-run.ru/ws/${this.state.token}`)

        this.websocket.onmessage = message => {
            const data = JSON.parse(message.data)
            if (data.extra === 'new_var') {
                const newData = [...this.state.dataSource];
                newData.unshift(data.result);
                this.setState({dataSource: newData,});
            }
        }

        // this.websocket.onclose = () => {
        //     this.websocket = null;
        //     setTimeout(this.ws_connect, 5000)
        // }
        //
        // this.websocket.onerror = () => {
        //     this.websocket = null;
        //     setTimeout(this.ws_connect, 5000)
        // }

    }


    heartbeat = () => {
        if (!this.websocket) return;
        if (this.websocket.readyState !== 1) return;
        this.websocket.send("heartbeat");
        setTimeout(this.ws_connect, 5000);
    }

    getQueryDelete = () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("id")
        return "/?" + urlParams.toString()
    }


    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        return (
            <div>
                <Button onClick={() => this.modalRequest()} style={{marginBottom: 10}}>Добавить переменную</Button>
                <Table
                    components={components}
                    rowKey={record => record.id}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={
                        {
                            total: this.state.count,
                            onChange: page => {
                                this.setState({currentPage: page})
                                this.fetch(page, "https://bpm-run.ru/api/vars")
                            },
                            pageSize: 10
                        }
                    }
                />
            </div>
        );
    }
}

export default Vars;