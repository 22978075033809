import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
// import { useLocation } from "react-router-dom";
import Home from './home';
import Demo from "../index";
import Edit from "./edit";
// import Events from "./events";


const Webpages = () => {
    return(
        <Router>
            <Routes>
                <Route exact path="/" element={<Demo/>} />
                <Route exact path="/create" element={<Home />} />
                <Route exact path="/edit" element={<Edit />} />
                {/*<Route exact path="/history" element={<Events />} />*/}
            </Routes>
        </Router>
    );
};

export default Webpages;