import React from 'react';
import {Button, Modal, Table} from 'antd';

import reqwest from "reqwest";
import ReactJson from "react-json-view";

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            currentPage: 1,
            id: this.getQuery().id,
            token: this.getQuery().token
        };
        this.columns = [
            {
                title: 'Ссылка',
                dataIndex: 'url',
                key: 'url',
            },
            {
                title: 'Код ответа',
                dataIndex: 'status_code',
                key: 'status_code',
            },
            {
                title: 'Протокол',
                dataIndex: 'protocol',
                key: 'protocol',
            },
            {
                title: 'Дата события',
                dataIndex: 'timestamp',
                key: 'timestamp',
                render: (ts) => {
                    const date = new Date(ts * 1000);
                    const strDate = ('0' + date.getDate()).slice(-2) + '.'
                        + ('0' + (date.getMonth()+1)).slice(-2) + '.'
                        + date.getFullYear();
                    const strTime = ('0' + date.getHours()).slice(-2) + ':'
                        + ('0' + date.getMinutes()).slice(-2) + ':'
                        + ('0' + date.getSeconds()).slice(-2)
                    return strDate + " " + strTime
                }
            },
            {
                title: 'Тело запроса',
                dataIndex: 'request_body',
                key: 'request_body',
                render: (body) => <Button onClick={() => {this.modalRequest(JSON.parse(body), "Тело запроса")}}>Просмотр</Button>
            },
            {
                title: 'Тело хука',
                dataIndex: 'hook_body',
                key: 'hook_body',
                render: (body) => <Button onClick={() => {this.modalRequest(JSON.parse(body), "Тело хука")}}>Просмотр</Button>
            },
            {
                title: 'Тело ответа',
                key: 'response',
                dataIndex: 'response',
                render: (body) => <Button onClick={() => {this.modalRequest(JSON.parse(body), "Тело ответа")}}>Просмотр</Button>
            },
        ];
        this.ws_connect();
        this.fetch(1, "https://bpm-run.ru/api/events")
    }

    modalRequest = (body, title) => {
        Modal.info({
            title: title,
            width: 750,
            maskClosable: true,
            content: (
                <div>
                    <ReactJson src={body} displayDataTypes={false}/>
                </div>
            ),
            onOk() {},
        });
    }

    getQuery = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return Object.fromEntries(urlSearchParams.entries());
    }

    fetch = (page, url = {}) => {
        reqwest({
            url: url,
            method: 'get',
            type: 'json',
            data: this.getPage(page)
        }).then(data => {
            this.setState({
                dataSource: data.result,
                count: data.length,
            });
        });
    }

    getPage = (page) => {
        return {
            id: this.state.id,
            token: this.state.token,
            limit: 10,
            offset: (page * 10) - 10,
        }
    }

    ws_connect = () => {
        this.websocket = new WebSocket(`wss://bpm-run.ru/ws/${this.state.token}`)

        this.websocket.onmessage = message => {
            const data = JSON.parse(message.data)
            if (data.extra === 'new_event') {
                if (this.state.currentPage === 1 && String(this.state.id) === String(data.sc_id)) {
                    const newData = [...this.state.dataSource];
                    newData.unshift(data.result);
                    if (newData.length > 10) {
                        this.setState({
                            dataSource: newData.slice(0, 10),
                            pageCount: this.state.pageCount + 1,
                            count: this.state.count + 1
                        });
                    }
                    else {
                        this.setState({
                            dataSource: newData,
                            pageCount: this.state.pageCount + 1,
                            count: this.state.count + 1
                        });
                    }
                }
            }
        }

        // this.websocket.onclose = () => {
        //     this.websocket = null;
        //     setTimeout(this.ws_connect, 5000)
        // }
        //
        // this.websocket.onerror = () => {
        //     this.websocket = null;
        //     setTimeout(this.ws_connect, 5000)
        // }

    }


    heartbeat = () => {
        if (!this.websocket) return;
        if (this.websocket.readyState !== 1) return;
        this.websocket.send("heartbeat");
        setTimeout(this.ws_connect, 5000);
    }

    getQueryDelete = () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("id")
        return "/?" + urlParams.toString()
    }

    first = () => {
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "GET", `https://bpm-run.ru/api/scenario_${this.state.id}?token=${this.state.token}`, false );
        xmlHttp.send( null );
        return JSON.parse(xmlHttp.response).result
    }

    onFinish = (data) => {

        let json = JSON.stringify(data);

        reqwest({
            url: `https://bpm-run.ru/api/code/?token=${this.getQuery().token}`,
            method: 'put',
            type: 'json',
            contentType: "application/json",
            data: json,
            success: () => this.Confirm(),
            error: (e) => console.log(e),
        });
    }

    render() {

        return (
            <div>
                <Table
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    style={{marginRight: 15}}
                    rowKey={record => record.id}
                    pagination={
                        {
                            total: this.state.count,
                            onChange: page => {
                                this.setState({currentPage: page})
                                this.fetch(page, "https://bpm-run.ru/api/events")
                            },
                            pageSize: 10
                        }
                    }
                />
                {/*<Link to={this.getQueryDelete()}>*/}
                {/*    <Button style={{marginLeft: 20, marginTop: 10}}>Назад</Button>*/}
                {/*</Link>*/}
            </div>

        );
    }
}

export default Events;