import React from 'react';
import {Button, Card, Modal} from 'antd';
import {CheckOutlined, StopOutlined} from "@ant-design/icons";
import reqwest from "reqwest";
import IntegrationModal from "./pages/install_integr";

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const { Meta } = Card;

// [
//     <CheckOutlined />,
//     <StopOutlined />
// ]

class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.check_pair();
        this.ws_connect();
    }

    modal = (body, title) => {
        Modal.info({
            title: title,
            width: 350,
            maskClosable: true,
            content: body,
            onOk() {},
        });
    }

    ws_connect = () => {
        this.websocket = new WebSocket(`wss://bpm-run.ru/ws/${params.token}`)

        this.websocket.onmessage = message => {
            const data = JSON.parse(message.data)
            if ("integration_status" in data) {
                if (data.integration_status === true) {
                    this.setState({status: <p style={{color: "green"}}>Работает</p>,
                        actions: [<StopOutlined onClick={() => this.disconnect()}/>]})
                }
                else if (data.integration_status === false){
                    this.setState({status: <p style={{color: "red"}}>Отключено</p>,
                        actions: [<CheckOutlined onClick={() => this.connect()}/>]})
                }
                else if (data.integration_status === "need_to_refresh"){
                    this.setState({actions: [<IntegrationModal button_name={"Обновить токен"}/>], status: <p style={{color: "red"}}>Необходимо обновить токен</p>})
                }
            }
        }

    }


    heartbeat = () => {
        if (!this.websocket) return;
        if (this.websocket.readyState !== 1) return;
        this.websocket.send("heartbeat");
        setTimeout(this.ws_connect, 5000);
    }

    disconnect = () => {
        reqwest({
            url: `https://bpm-run.ru/api/integration_unpair?token=${params.token}`,
            method: 'get',
            contentType: "application/json",
            type: 'json',
            success: () => this.modal("Интеграция отключена", "Успех"),
            error: (e) => console.log(e)
        })
    }

    connect = () => {
        reqwest({
            url: `https://bpm-run.ru/api/integration_on?token=${params.token}`,
            method: 'get',
            contentType: "application/json",
            type: 'json',
            success: () => this.modal("Интеграция включена", "Успех"),
            error: (e) => console.log(e)
        })
    }


    check_pair = () => {
        reqwest({
            url: `https://bpm-run.ru/api/check_pair?token=${params.token}`,
            method: 'get',
            contentType: "application/json",
            type: 'json',
        }).then((data) => {
            this.setState({int_status: data.integration_status, paired: data.result})
            if (this.state.paired !== "paired") {
                this.setState({actions: [<IntegrationModal button_name={"Установить виджет"}/>]})
            }

            else if (data.integration_status === "need_to_refresh") {
                this.setState({actions: [<IntegrationModal button_name={"Обновить токен"}/>], status: <p style={{color: "red"}}>Необходимо обновить токен</p>})
            }

            else {
                if (this.state.int_status === true) {
                    this.setState({status: <p style={{color: "green"}}>Работает</p>,
                        actions: [<StopOutlined onClick={() => this.disconnect()}/>]})
                }
                else if (this.state.int_status === false) {
                    this.setState({status: <p style={{color: "red"}}>Отключено</p>,
                        actions: [<CheckOutlined onClick={() => this.connect()}/>]})
                }
            }


        })
    }

    render() {

        return (
            <Card
                style={{ width: 250 }}
                cover={
                    <img
                        alt="amo_logo"
                        src="https://www.amocrm.ru/views/pages/landing/images/about_us/press_stuff/amocrm-logo-dark.png"
                    />
                }
                actions={this.state.actions}
            >
                <Meta
                    title="AmoCRM"
                    description={this.state.status}
                />
            </Card>
        );
    }
}

export default Cards;