import React from 'react';
import { Modal, Button } from 'antd';
import reqwest from "reqwest";


class LogsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
        };
    }
    render() {

        const showModal = () => {
            this.setState({isModalVisible: true});
            reqwest({
                url: "https://bpm-run.ru/api/log_scenario",
                method: 'get',
                type: 'json',
                data: {"token": this.props.token, "id": this.props.id}
            }).then(data => {
                if ('result' in data) {
                    this.setState({
                        logs: data.result
                    });
                }
            });
        };

        const handleOk = () => {
            this.setState({isModalVisible: false});
        };

        const handleCancel = () => {
            this.setState({isModalVisible: false});
        };

        return (
            <>
                <Button onClick={showModal}>
                    Просмотр
                </Button>
                <Modal title="Логи скрипта" visible={this.state.isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <div style={{whiteSpace: 'pre-wrap'}}>{this.state.logs}</div>
                </Modal>
            </>
        );
    }
}

export default LogsModal;