import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {Button, Space, Switch, Table, Tabs, Tooltip, Popconfirm} from 'antd';

import Webpages from "./pages";
import {Link} from "react-router-dom";

import reqwest from 'reqwest';
import LogsModal from "./logs_modal";
import Cards from "./cards";

// import { Menu } from 'antd';

const { TabPane } = Tabs;

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());


function callback(key) {
    if (key === "1") {
        console.log("s")
    }
    else {
        console.log("h")
    }
}

const edit_request = (row) => {
    const data = JSON.stringify({
        name: row.name,
        description: row.description,
        history_token: row.history_token,
        id: row.id,
        owner_id: row.owner_id,
        run_freq: row.run_freq,
        tags: row.tags,
        status: row.status
    })
    reqwest({
        url: `https://bpm-run.ru/api/code/?token=${params.token}`,
        method: 'put',
        contentType: "application/json",
        type: 'json',
        data: data
    })
}

const getPage = page => {
    return {
        token: params.token,
        limit: 100,
        offset: (page * 100) - 100,
    }
}

class Demo extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Название сценария',
                dataIndex: 'name',
                key: 'name',
                width: 400,
                render: (name, row) => (
                    <Tooltip title={row.description}>
                        <span>{name}</span>
                    </Tooltip>
                )
            },
            {
                title: 'Webhook',
                dataIndex: 'hook',
                key: 'hook',
                render: (hook) => <a rel="noreferrer" target="_blank" href={"https://bpm-run.ru/api/hook_" + hook}>{"https://bpm-run.ru/api/hook_" + hook}</a>
            },
            {
                title: 'Частота запуска (мс)',
                dataIndex: 'run_freq',
                key: 'run_freq',
                width: 165,
            },
            // {
            //     title: 'События',
            //     dataIndex: 'history_token',
            //     key: 'history_token',
            //     width: 10,
            //     render: (token, row) =>
            //         <Link to={"/history" + window.location.search + `&id=${row.id}`}>
            //             <Button>Просмотр</Button>
            //         </Link>
            // },
            {
                title: 'Статус (вкл/выкл)',
                dataIndex: 'status',
                key: 'status',
                width: 5,
                sorter: (a, b) => a.status - b.status,
                render: (checked, row) => {
                    return <Switch checked={checked} onClick={(checked) => this.handleChangeStatus(checked, row)}/>
                }
            },
            {
                title: 'Тэги',
                key: 'tags',
                dataIndex: 'tags',
                // render: tags => (
                //     <>
                //         {tags.map(tag => {
                //             let color = tag.length > 5 ? 'geekblue' : 'green';
                //             if (tag === 'loser') {
                //                 color = 'volcano';
                //             }
                //             return (
                //                 <Tag color={color} key={tag}>
                //                     {tag.toUpperCase()}
                //                 </Tag>
                //             );
                //         })}
                //     </>
                // ),
            },
            {
                title: 'Логи',
                key: 'logs',
                width: 10,
                render: (d, row) => (
                    <Space size="middle">
                        <LogsModal token={params.token} id={row.id}/>
                    </Space>
                )
            },
            {
                title: 'Действие',
                key: 'action',
                width: 20,
                render: (d, row) => (
                    <Space size="middle">
                        <Link to={"/edit" + window.location.search + `&id=${row.id}`}>
                            <Button onClick={() => this.websocket.close()}>Изменить</Button>
                        </Link>
                        <Popconfirm
                            title="Вы уверены что хотите удалить сценарий?"
                            onConfirm={() => this.handleDelete(row)}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Button>Удалить</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        ];

        this.state = {
            count: 0,
            dataSource: []
        };

        this.fetch(1, `https://bpm-run.ru/api/scenarios?token=${params.token}`)
        this.ws_connect()

    }

    ws_connect = () => {
        this.websocket = new WebSocket(`wss://bpm-run.ru/ws/${params.token}`)

        this.websocket.onmessage = message => {
            const data = JSON.parse(message.data)

            if (data.extra === "new_script") {
                const newData = [...this.state.dataSource];
                newData.unshift(data.result);
                this.setState({dataSource: newData,});

            }

            if (data.extra === "script_edit") {
                const newData = [...this.state.dataSource];
                const index = newData.findIndex((item) => data.result.id === item.id);
                if (index !== -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {...item, ...data.result});
                    this.setState({
                        dataSource: newData,
                    });
                }
            }
        }

        // this.websocket.onclose = () => {
        //     this.websocket = null;
        //     setTimeout(this.ws_connect, 5000)
        // }

        // this.websocket.onerror = () => {
        //     this.websocket = null;
        //     setTimeout(this.ws_connect, 5000)
        // }

    }


    heartbeat = () => {
        if (!this.websocket) return;
        if (this.websocket.readyState !== 1) return;
        this.websocket.send("heartbeat");
        setTimeout(this.ws_connect, 5000);
    }

    handleChangeStatus = (status, row) => {
        row.status = status
        edit_request(row)
    }

    handleDelete = (row) => {
        reqwest({
            url: `https://bpm-run.ru/api/delete_sc?token=${params.token}&id=${row.id}`,
            method: 'get',
            type: 'json',
        })
        const dataSource = [...this.state.dataSource];
        const row_index = dataSource.findIndex((item) => item.id === row.id)
        dataSource.splice(row_index, 1);
        this.setState({
            dataSource: dataSource,
        });
    }

    newMenu = (val) => {
        if (val.key === "1") console.log(typeof(val.key))
    }

    fetch = (page, url = {}) => {
        reqwest({
            url: url,
            method: 'get',
            type: 'json',
            data: getPage(page)
        }).then(data => {
            console.log(data)
            if ('result' in data) {
                this.setState({
                    dataSource: data.result,
                    count: data.result.length,
                    pageCount: data.count,
                });
            }
            else if ('status' in data) {
                ReactDOM.render(<h1 className="error">{data.status.toUpperCase()}</h1>, document.getElementById('root'))
            }

        });
    }


    render() {
        return (
            <div>
                <Tabs defaultActiveKey="1" onChange={callback} style={{marginLeft: 15,}}>
                    <TabPane tab="Сценарии" key="1">

                        <Link to={"/create" + window.location.search}>
                            <Button type="primary" style={{marginBottom: 10}} className="add-new-payment" >
                                Добавить cценарий
                            </Button>
                        </Link>

                        <Table
                            columns={this.columns}
                            dataSource={this.state.dataSource}
                            style={{marginRight: 15}}
                            rowKey={record => record.id}
                        />
                    </TabPane>

                    <TabPane tab="Инструкции" key="3">
                        <h2>Использование переменных :</h2>
                        <div>
                            1) Во вкладке "Переменные" добавить желаемые переменные <u>ВНИМАНИЕ, переменные в ЯП Python не могут начинаться с цифры!</u><br/>
                            2) В создаваемом скрипте вы можете явно обращаться к переменной по ключу, указанному при создании
                            <br/><br/>
                            <b>Например:</b> вы создали переменную с ключом <b>var1</b> и значением <b>Hello, World</b><br/>
                            В скрипте вы можете обратиться к данной переменной по ключу <b>var1</b>, то есть <code>print(var1)</code> выведет в логи "Hello, World"
                        </div>
                        <br/>
                        <h2>Использование хуков :</h2>
                        <div>
                            1) Вы можете использовать веб-хуки своих сценариев, используя методы <b>GET</b> и <b>POST</b><br/>
                            2) При использовании <b>GET</b> метода веб-хука вы можете передать различные данные в свой скрипт,
                            передавая их в качестве параметров, <br/><b>Например:</b> https://bpm-run.ru/hook_123123123123<b>?var1=Hello&var2=World</b><br/>
                            Также, вы можете выключать или включать скрипт, добавляя параметр <b>action</b> со значениями: <b>on</b> и <b>off</b><br/>
                            3) При использовании <b>POST</b> метода веб-хука вы можете передать различные данные в свой скрипт,
                            передавая их в качестве тела запроса <b>(application/json, application/x-www-form-urlencoded)</b>, <br/><b>Например:</b> POST запрос на https://bpm-run.ru/hook_123123123123
                            с телом <br/>
                            <br/>
                            <b>application/json</b><br/>
                            <code>
                            &#123;<br/>
                            &nbsp;"var1": "Hello", <br/>
                            &nbsp;"var2": "World" <br/>
                            &#125;
                        </code>
                            <br/><br/>
                            <b>application/x-www-form-urlencoded</b><br/>
                            <code>
                                var1=Hello&var2=World
                            </code>
                            <br/><br/>
                            4) В создаваемом скрипте вы можете получить переданные данные, прописав данные строки: <br/>
                            <code>
                                <br/>
                                import sys<br/>
                                data = &#123;&#125;<br/>
                                for i in sys.argv[1:]:<br/>
                                &nbsp;var = i.split("=")<br/>
                                &nbsp;data[var[0]] = var[1]<br/>
                            </code>
                            <br/> Далее в коде вы можете обращаться к данным, переданным из тела веб-хука по ключу из переменной <b>data</b><br/>
                            <b>Например:</b> <code>print(data["var1"])</code> <b>или же</b> <code>print(data.get("var1"))</code>
                        </div>
                    </TabPane>

                    <TabPane tab="Интеграции" key="2">

                        <Cards />

                    </TabPane>

                    <TabPane tab={<a className="button" href={"https://bpm-run.ru/api/docs"}>Документация</a>} />
                </Tabs>
            </div>
        );
    }
}

export default Demo;

ReactDOM.render(<Webpages />, document.getElementById("root"));