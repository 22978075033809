import React from 'react';
import {Button, Form, Input, InputNumber, Modal, Tabs} from 'antd';
import CodeMirror, {basicSetup} from "@uiw/react-codemirror";
import {python} from "@codemirror/lang-python";
import {Link} from "react-router-dom";

import reqwest from "reqwest";
import Events from "./events";
import Vars from "./vars";
// import Events from "./events";

const { TabPane } = Tabs;
const { TextArea } = Input;

class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            token: this.getQuery().token,
            id: this.getQuery().id,
            home_url: `https://bpm-run.ru/?token=${this.getQuery().token}`
        };

        // this.tabsHandler();
    }

    getQuery = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return Object.fromEntries(urlSearchParams.entries());
    }

    Confirm = () => {
        return (
            Modal.info({
                title: 'Уведомление',
                content: (
                    <div>Сохранение прошло успешно!</div>
                ),
                okText: <a href={this.state.home_url}>OK</a>
            })
        )
    }

    getQueryDelete = () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("id")
        return "/?" + urlParams.toString()
    }

    first = () => {
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "GET", `https://bpm-run.ru/api/scenario_${this.state.id}?token=${this.state.token}`, false );
        xmlHttp.send( null );
        return JSON.parse(xmlHttp.response).result
    }

    onFinish = (data) => {
        const post_data = {
            id: this.getQuery().id,
            name: data.name,
            description: data.description,
            tags: data.tags,
            code: data.code,
            run_freq: data.run_freq,
            status: true
        }

        let json = JSON.stringify(post_data);

        reqwest({
            url: `https://bpm-run.ru/api/code/?token=${this.getQuery().token}`,
            method: 'put',
            type: 'json',
            contentType: "application/json",
            data: json,
            success: () => this.Confirm(),
            error: (e) => console.log(e),
        });
    }

    render() {

        const init_val = this.first()

        return (
            <div>

                <Tabs defaultActiveKey="1" style={{marginLeft: 10}}>
                    <TabPane tab="Код" key="1">

                        <div style={{marginLeft: 10, marginBottom: 10, fontWeight: "bold"}}>Токен для записи событий: {init_val.history_token}</div>

                        <Form
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 14 }}
                            onFinish={this.onFinish}
                            initialValues={init_val}
                        >
                            <Form.Item
                                label="Название сценария"
                                name="name"
                                // style={{marginLeft: 10}}
                                rules={[{ required: true, message: 'Введите название сценария' }]}
                            >
                                <Input className={"scFData"}/>
                            </Form.Item>

                            <Form.Item
                                label="Описание сценария"
                                name="description"
                                // style={{marginLeft: 20}}
                                rules={[{ required: true, message: 'Введите описание сценария' }]}
                            >
                                <TextArea style={{marginLeft: 10, width: 850}} rows={2}/>
                            </Form.Item>

                            <Form.Item
                                label="Тэги сценария"
                                name="tags"
                                // style={{marginLeft: 10}}
                                rules={[{ required: true, message: 'Введите тэги сценария' }]}
                            >
                                <Input className={"scData"}/>
                            </Form.Item>

                            <Form.Item
                                label="Частота запуска (мс)"
                                name="run_freq"
                                // style={{marginLeft: 10}}
                                rules={[{ required: true, message: 'Введите частоту запуска сценария' }]}
                            >
                                <InputNumber style={{marginLeft: 10}} min={1}/>
                            </Form.Item>

                            <Form.Item
                                label="Код"
                                name="code"
                                // style={{marginLeft: 20}}
                                rules={[{ required: true, message: 'Введите код' }]}
                            >
                                <CodeMirror
                                    height="600px"
                                    width="1100px"
                                    extensions={[basicSetup, python()]}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className={"saveButton"}>Сохранить</Button>
                                <Link to={this.getQueryDelete()}>
                                    <Button className={"saveButton"}>Отмена</Button>
                                </Link>
                            </Form.Item>

                        </Form>

                    </TabPane>
                    <TabPane tab="События" key="2">
                        <Events />
                    </TabPane>
                    <TabPane tab="Переменные" key="3">
                        <Vars />
                    </TabPane>
                    <TabPane tab={<a className="button" href={`https://bpm-run.ru/?token=${this.getQuery().token}`}>Сценарии</a>} />
                </Tabs>

            </div>
        );
    }
}

export default Edit;