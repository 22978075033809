import React from 'react';
import CodeMirror, {basicSetup} from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';

import "./home.css"
import {Button, Input, Form, Tabs, InputNumber, Modal} from "antd";
// import VarsTable from "../varstable";
import {Link} from "react-router-dom";
import reqwest from "reqwest";

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const { TabPane } = Tabs;
const { TextArea } = Input;

const code = "print('hello world')"

const { createHash } = require('crypto');

const gen_ht = () => {
    const str = [...Array(25)].map(()=>(~~(Math.random()*36)).toString(36)).join('')
    return createHash('sha256').update(str).digest('hex');
}

const home_url = `https://bpm-run.ru/?token=${params.token}`

const Confirm = () => {
    return (
        Modal.info({
            title: 'Уведомление',
            content: (
                <div>Скрипт создан!</div>
            ),
            okText: <a href={home_url}>OK</a>
        })
    )
}

const onFinish = (data, hash) => {
    console.log(data)
    const post_data = {
        name: data.name,
        description: data.description,
        tags: data.tags,
        code: data.code,
        hook: gen_ht().slice(0, 25),
        run_freq: data.run_freq,
        status: true,
        history_token: hash
    }

    let json = JSON.stringify(post_data);

    reqwest({
        url: `https://bpm-run.ru/api/code/?token=${params.token}`,
        method: 'post',
        type: 'json',
        contentType: "application/json",
        data: json,
        success: () => Confirm(),
        error: (e) => console.log(e),
    });
}

const Home = () => {
    const generated_hash = gen_ht();
    return (
        <div>

            <Tabs defaultActiveKey="1" style={{marginLeft: 10}}>
                <TabPane tab="Код" key="1">

                    <div style={{marginLeft: 10, marginBottom: 10, fontWeight: "bold"}}>Токен для записи событий: {generated_hash}</div>

                    <Form
                        labelCol={{ span: 2 }}
                        wrapperCol={{ span: 14 }}
                        onFinish={(data) => onFinish(data, generated_hash)}
                        initialValues={{ run_freq: 10}}
                    >
                        <Form.Item
                            label="Название сценария"
                            name="name"
                            rules={[{ required: true, message: 'Введите название сценария' }]}
                        >
                            <Input className={"scFData"}/>
                        </Form.Item>

                        <Form.Item
                            label="Описание сценария"
                            name="description"
                            style={{marginLeft: 11}}
                            rules={[{ required: true, message: 'Введите описание сценария' }]}
                        >
                            <TextArea rows={2}/>
                        </Form.Item>

                        <Form.Item
                            label="Тэги сценария"
                            name="tags"
                            rules={[{ required: true, message: 'Введите тэги сценария' }]}
                        >
                            <Input className={"scData"}/>
                        </Form.Item>

                        <Form.Item
                            label="Частота запуска (мс)"
                            name="run_freq"
                            rules={[{ required: true, message: 'Введите частоту запуска сценария' }]}
                        >
                            <InputNumber style={{marginLeft: 10}} min={1}/>
                        </Form.Item>

                        <Form.Item
                            label="Код"
                            name="code"
                            rules={[{ required: true, message: 'Введите код' }]}
                        >
                                <CodeMirror
                                    placeholder={code}
                                    height="600px"
                                    width="1300px"
                                    extensions={[basicSetup, python()]}
                                />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className={"saveButton"}>Сохранить</Button>
                            <Link to={"/" + window.location.search}>
                                <Button className={"saveButton"}>Отмена</Button>
                            </Link>
                        </Form.Item>

                    </Form>

                </TabPane>

                <TabPane tab={<a className="button" href={`https://bpm-run.ru/?token=${params.token}`}>Сценарии</a>} />
                {/*<TabPane tab="Переменные" key="2">*/}
                {/*    <VarsTable/>*/}
                {/*</TabPane>*/}
            </Tabs>

        </div>
    );
};

export default Home;